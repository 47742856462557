import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import PeterImage from '../images/team/peter.jpg'
import RichieImage from '../images/team/richie.jpg'

const About = () => (
  <Layout>
    <Seo title="Terms" />
    <div className="SubPage">
      <div className="Subpage__header">
        <h1>Terms of Use</h1>
        <p>Updated: February 7, 2022</p>
      </div>
      <div className="privacy" style={{margin: "24px auto", padding: "0 12px"}}>
        <p>Nitor Fitness LLC is a developer of fitness and fitness related apps. Any references to apps is assumed to reference those developed by Nitor Fitness and whos use are goverend under the terms of this agreement. By using any Nitor Fitness apps, website or web software (the \"service\") you are agreeing to be bound by the following terms and conditions. If you disagree with any of the terms below please do not use Worlds Crafter. These terms govern the relationship between you and your use of the service and any related media such as social media.
        </p>
        <p>We reserve the right to change, mofify or update the terms of service any anytime with or without notice. Your continued use of the service constitutes your acceptance of these changes. Please check back regularly as this web page will contain any such updats.</p>



        <h2>General Terms</h2>
        <ul>
          <li>You must be 18 years or older to use the service.</li>
          <li>You must provide valid and legally correct information when signing up for the service.</li>
          <li>You are responsible for the security of your account, including usernames and passwords.</li>
          <li>You may not use the service for any illegal purposes.</li>
          <li>You must place undue burden on the service including uploads and bandwith usage. We reserve the right to block users that abuse access.</li>
          <li>Your use of the service must not violate any laws in your jurisdiction.</li>
          <li>We reserve the right to cancel the service for any account at any time for any reason. If your service is cancelled you are not entitled to a refund.</li>
          <li>You agree to not copy, duplicate, or sell any part of the service or website.</li>
          <li>The service is provided \"As-Is\", and \"As-Available\". We do not guarantee the service will be 100% available or meet your specific requirements and you agree we have no liabililty for any loss incurred due to your inability to access the site.</li>
          <li>The failure to enforce any right or provision of these terms does not constitute a waiver of that right or provision.</li>


          <h2>Limitation of Liability</h2>
          <p>Except in jurisdictions where such provisions are restricted, in no event will Nitor Fitness be liable to you or any third person for any direct, indirect, consequential, exemplary, incidental, special or punitive damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Nitor Fitness has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Service; (ii) the cost of procurement of substitute goods and services resulting from your inability to access or obtain any goods, data, information or services through or from the Service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; or (v) any content posted on the Website or transmitted to Registrants or Members; or (vi) any inaccurate or out-of-date content produced by the tools or published in the guides or blog, or Website; or (vii) any other matter relating to the Service. Nothwithstanding any provision to the contrary, Nitor Fitness’s liability to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to Nitor Fitness in the twelve (12) months prior to the claimed injury or damage.</p>


          <h2>Indemnity</h2>
          <p>You agree to indemnify and hold Nitor Fitness, its subsidiaries, affiliates, officers, agents, and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your use of the Service in violation of this Agreement and/or arising from a breach of this Agreement and/or any breach of your representations and warranties and/or your negligent or willful acts, and/or the violation by you of Nitor Fitness' or any third party’s rights, including without limitation privacy rights, other property rights, trade secret, proprietary information, trademark, copyright, or patent rights, and claims for libel slander, or unfair trade practices in connection with the use or operation of the Service. You obligation to indemnify will survive the expiration or termination of this Agreement by either party for any reason.</p>

          <h2>Questions?</h2>
          <p>If you have any questions or concerns with the above policies you can contact us at support@worldscrafter.com.</p>

          <h2>Severability</h2>
          <p>In case any provision or term in this contract shall be held invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby.</p>

        </ul>
      </div>
    </div>
  </Layout>
)

export default About
